import moment from "moment-timezone";
import React from "react";
import { NavLink as RNavLink, useNavigate } from "react-router-dom";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import {
  FACEBOOK_SHARE,
  INSTAGRAM_SHARE,
  LINKEDIN_SHARE,
  TWITTER_SHARE,
} from "../../../_eaFruitsCms/helpers/Constants";
import { toAbsoluteUrl } from "../../../helper/AssetHelpers";
import { KTSVG } from "../../../helper/components/KTSVG";

const Footer = (props: any) => {
  const navigate: any = useNavigate();
  return (
    <>
      <footer className="bg-primary">
        <Container fluid="xl">
          <Row>
            <Col md={4}>
              <div className="footer-col">
                <a href="JAVASCRIPT:void(0)" onClick={() => navigate("/")}>
                  <img
                    src={toAbsoluteUrl(
                      "/media/logos/logo-eafoods-website-footer.png"
                    )}
                    srcSet={toAbsoluteUrl(
                      "/media/logos/logo-eafoods-website-footer.png"
                    )}
                    alt=""
                    className="mb-10px"
                  />
                </a>
                <p className="mb-3 pb-1">
                  We are the leading Data and Tech Driven Food Distribution
                  Company in Tanzania aiming at boosting access to digital
                  commerce for businesses in Africa.
                </p>
                <div className="d-flex">
                  <img
                    src={toAbsoluteUrl("/media/images/img_african.png")}
                    srcSet={toAbsoluteUrl(
                      "/media/images/img_african@2x.png 2x"
                    )}
                    alt=""
                    className="mb-3 me-2"
                  />
                  <img
                    src={toAbsoluteUrl("/media/images/img_finca.png")}
                    srcSet={toAbsoluteUrl("/media/images/img_finca@2x.png 2x")}
                    alt=""
                    className="mb-3"
                  />
                </div>
              </div>
            </Col>
            <Col md={8} className="ps-lg-5">
              <Row>
                <Col md={4}>
                  <div className="footer-col">
                    <h5 className="footer-heading text-white">EA Foods</h5>
                    <Nav className="footer-nav ps-0">
                      <NavItem>
                        <NavLink tag={RNavLink} to="/about">
                          About Us
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RNavLink} to="/farmer-services">
                          Services
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RNavLink} to="/product">
                          Products
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink tag={RNavLink} to="/success-stories">
                          Success Stories
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="footer-col">
                    <h5 className="footer-heading text-white">Quick Links</h5>
                    <Nav className="footer-nav ps-0">
                      <NavItem>
                        <NavLink tag={RNavLink} to="/investors-partners">
                          Investors & Partners
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RNavLink} to="/career">
                          Careers
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink tag={RNavLink} to="/terms-of-use">
                          Terms of Use
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink tag={RNavLink} to="/privacy-policy">
                          Privacy Policy
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="footer-col">
                    <h5 className="footer-heading text-white">Contact Us</h5>
                    <Nav className="footer-nav ps-0">
                      <NavItem>
                        <NavLink href="mailto:hello@eafoods.com">
                          <KTSVG
                            path="/media/icons/ic_mail.svg"
                            className="ic me-2"
                          />
                          hello@eafoods.com
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink href="callto:+255 672 22 77 77">
                          <KTSVG
                            path="/media/icons/ic_call.svg"
                            className="ic me-2"
                          />
                          +255 672 22 77 77
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="social">
                      <ul className="text-white list-unstyled mb-0 d-flex flex-wrap">
                        <li>
                          <a href={FACEBOOK_SHARE} target={"_blank"}>
                            <KTSVG
                              path="/media/icons/ic_fb.svg"
                              className="ic"
                            />
                          </a>
                        </li>
                        <li>
                          <a href={INSTAGRAM_SHARE} target={"_blank"}>
                            <KTSVG
                              path="/media/icons/ic_insta.svg"
                              className="ic"
                            />
                          </a>
                        </li>
                        <li>
                          <a href={TWITTER_SHARE} target={"_blank"}>
                            <KTSVG
                              path="/media/icons/ic_twit.svg"
                              className="ic"
                            />
                          </a>
                        </li>
                        <li>
                          <a href={LINKEDIN_SHARE} target={"_blank"}>
                            <KTSVG
                              path="/media/icons/ic_linkedin.svg"
                              className="ic"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className="my-4" />
          <Row className="justify-content-center">
            <Col md={6}>
              <p className="my-1">
                © {moment().format("yyyy")} All rights reserved by EA Foods
                Limited.
              </p>
            </Col>
            <Col md={6} className="text-md-end">
              <p className="my-1">
                Designed & Developed by
                <a
                  href="https://www.moweb.com/"
                  target={"_blank"}
                  className="ms-1 footer-link"
                >
                  Moweb Limited
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
